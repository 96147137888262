import React, { useState } from 'react'
import logo from './logo.svg'
import './App.css'
import axios from 'axios'
import { Routes, Route } from 'react-router-dom'
import Layout from './components/Layout'
import Patients from './components/Patients/Patients'
import Facilities from './components/Facilities/Facilities'
import Inquire from './components/Inquire/Inquire'
import Header from './components/Header/Header'
import Login from './components/Login/Login'
import Register from './components/Register/Register'
import RequiredAuth from './components/RequiredAuth'
import Home from './components/Home/Home'
import CurrentPatient from './components/CurrentPatient/CurrentPatient'
import useAuth from './hooks/useAuth'
import ThankYou from './components/ThankYou/ThankYou'

function App() {
    const { auth }: any = useAuth()
    return (
        <div className="App">
            <div className="App-header-patient">
                <Header />
                {/* For faster development */}
                {/* {true && <CurrentPatient />} */}
                {/* TODO uncomment in production */}
                {auth && <CurrentPatient />}
            </div>
            <main>
                <Routes>
                    <Route path="/" element={<Layout />}>
                        <Route path="/" element={<Home />}></Route>
                        <Route path="/inquire" element={<Inquire />}></Route>
                        <Route path="/register" element={<Register />}></Route>
                        <Route path="/login" element={<Login />}></Route>
                        <Route path="/thank-you" element={<ThankYou />}></Route>
                        {/* <Route
                        path="/facility/:id"
                        element={<Facility />}
                      ></Route> */}
                        {/* <Route
                        path="/user/:id"
                        element={<User />}
                      ></Route> */}
                        {/* <Route
                        path="/patient/:id"
                        element={<Patient />}
                      ></Route> */}
                        <Route element={<RequiredAuth />}>
                            <Route
                                path="/patients"
                                element={<Patients />}
                            ></Route>
                            <Route
                                path="/facilities"
                                element={<Facilities />}
                            ></Route>
                            {/* TODO users table */}
                        </Route>
                        <Route
                            path="/*"
                            element={<div>Page not found</div>}
                        ></Route>
                    </Route>
                </Routes>
            </main>
            {/* TODO footer goes here if needed */}
        </div>
    )
}

export default App

import React, { useEffect, useState } from 'react'
import '../table-style.css'
import axiosClient from '../../api/axiosConfig'
import './Patients.css'
import { Dropdown, Modal, Form } from 'react-bootstrap'
// import MatchModal from '../MatchModal/MatchModal'
import { useCurrentPatient } from '../../state'

function Patients() {
    const [patients, setPatients] = useState([])
    const [error, setError] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [currentPatient, setCurrentPatient] = useCurrentPatient()
    console.log(`Example currentPatient: `, currentPatient)
    // const [showMatchFacility, setShowMatchFacility] = useState(false)

    useEffect(() => {
        async function getPatients() {
            setIsLoading(true)
            try {
                const response: any = await axiosClient.get('/patients')
                console.log(response)
                setPatients(response.data.patients)
            } catch (error: any) {
                console.log(error)
                setError(error.message)
            } finally {
                setIsLoading(false)
            }
        }
        getPatients()
    }, [])

    function handlePlace() {}
    return (
        <div className="Patients">
            {/* not in use */}
            {/* <MatchModal
                show={showMatchFacility}
                handleClose={() => setShowMatchFacility(false)}
            /> */}
            <h2 className="Patients-title">Patients</h2>
            {isLoading ? (
                <h2>Loading...</h2>
            ) : error ? (
                <div>{error}</div>
            ) : (
                <table id="table-style">
                    <thead>
                        <tr>
                            <th>First Name</th>
                            <th>Contact Person</th>
                            <th>Telephone</th>
                            <th>Email</th>
                            <th>Status</th>
                            <th>Matched facilities</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {patients.map((patient: any) => {
                            return (
                                <tr key={patient.id}>
                                    <td>{patient.firstName}</td>
                                    <td>{patient.contactPerson}</td>
                                    <td>{patient.telephone}</td>
                                    <td>{patient.email}</td>
                                    <td>{patient.status}</td>
                                    <td>
                                        <ul>
                                            {patient.matchedFacilities?.map(
                                                (facility: any) => {
                                                    return (
                                                        <li>
                                                            <span className="matched-facilities-span">
                                                                {
                                                                    facility[
                                                                        'Facility Name'
                                                                    ]
                                                                }
                                                            </span>
                                                        </li>
                                                    )
                                                }
                                            )}
                                        </ul>
                                    </td>
                                    <td>
                                        <Dropdown>
                                            <Dropdown.Toggle
                                                variant="success"
                                                id="dropdown-basic"
                                            ></Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                <Dropdown.Item
                                                    onClick={() => {
                                                        setCurrentPatient(
                                                            patient
                                                        )
                                                    }}
                                                >
                                                    Select patient
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                    onClick={handlePlace}
                                                >
                                                    Place
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            )}
        </div>
    )
}

export default Patients

// TODO need to know workflow
export const status = {
  MATCHING:'MATCHING',
  EMAILED_MATCHES:'EMAILED_MATCHES',
  PLACED:'PLACED',
}

export const timeframeConstant = {
    ASAP: 'ASAP',
    ONE_TO_THREE_MONTHS: 'ONE_TO_THREE_MONTHS',
    THREE_TO_SIX_MONTHS: 'THREE_TO_SIX_MONTHS',
}

export const preferredCitiesOptions = ['riverside', 'colton', 'moreno valley', 'norco', 'corona', 'sun city', 'murrieta', 'temecula', 'hemet', 'san bernardino', 'perris', 'menifee', 'ontario', 'rancho cucomonga' ]
import './Register.css'
import { useState, useRef, useEffect } from 'react'
import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'
import axiosClient from '../../api/axiosConfig'

const Register = () => {
    const nameRef: any = useRef()

    const [name, setName] = useState('')
    const [validName, setValidName] = useState(false)

    const [user, setUser] = useState('')
    const [validUserName, setValidUserName] = useState(false)

    const [pwd, setPwd] = useState('')
    const [validPwd, setValidPwd] = useState(false)

    const [matchPwd, setMatchPwd] = useState('')
    const [validMatch, setValidMatch] = useState(false)

    const NAME_REGEX = /^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$/

    const USER_REGEX = /^(?=[a-zA-Z0-9._]{8,20}$)(?!.*[_.]{2})[^_.].*[^_.]$/

    const PWD_REGX =
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/

    const [submitSuccess, setSubmitSuccess] = useState('')

    useEffect(() => {
        nameRef.current.focus()
    }, [])

    useEffect(() => {
        const result = NAME_REGEX.test(name)
        setValidName(result)
    }, [name])

    useEffect(() => {
        const result = USER_REGEX.test(user)
        setValidUserName(result)
    }, [user])

    useEffect(() => {
        const result = PWD_REGX.test(pwd)
        setValidPwd(result)
        const match = pwd === matchPwd
        setValidMatch(match)
    }, [pwd, matchPwd])

    const postData = async (e: any) => {
        e.preventDefault()
        try {
            const userReg = {
                firstName: e.target.firstName.value,
                username: e.target.username.value,
                roles: 'ROLE_USER',
                password: e.target.password.value,
            }

            const resp = await axiosClient.post('/auth/register', userReg)

            setSubmitSuccess(resp.data.message)
            // TODO redirect to login and clear form
        } catch (error) {
            console.log(error)
            setSubmitSuccess('You have not registered successfully ' + error)
        }
    }

    return (
        <div className="Register">
            <h2>Register</h2>
            <form className="form" onSubmit={postData}>
                <label>
                    <div>
                        First Name:
                        <span className={validName ? 'valid' : 'hide'}>
                            <FontAwesomeIcon
                                icon={faCheck}
                                className="valid-icon"
                            />
                        </span>
                        <span className={validName || !name ? 'hide' : 'valid'}>
                            <FontAwesomeIcon
                                icon={faTimes}
                                className="invalid-icon"
                            />
                        </span>
                    </div>
                    <input
                        type="text"
                        name="firstName"
                        ref={nameRef}
                        autoComplete="off"
                        onChange={(e) => setName(e.target.value)}
                        required
                    />
                </label>
                <label>
                    <div>
                        Username:
                        <span className={validUserName ? 'valid' : 'hide'}>
                            <FontAwesomeIcon
                                icon={faCheck}
                                className="valid-icon"
                            />
                        </span>
                        <span
                            className={
                                validUserName || !user ? 'hide' : 'valid'
                            }
                        >
                            <FontAwesomeIcon
                                icon={faTimes}
                                className="invalid-icon"
                            />
                        </span>
                    </div>
                    <input
                        type="text"
                        name="username"
                        autoComplete="off"
                        onChange={(e) => setUser(e.target.value)}
                        required
                    />
                </label>

                <label>
                    <div>
                        Password:
                        <span className={validPwd ? 'valid' : 'hide'}>
                            <FontAwesomeIcon
                                icon={faCheck}
                                className="valid-icon"
                            />
                        </span>
                        <span className={validPwd || !pwd ? 'hide' : 'valid'}>
                            <FontAwesomeIcon
                                icon={faTimes}
                                className="invalid-icon"
                            />
                        </span>
                    </div>
                    <input
                        type="password"
                        name="password"
                        autoComplete="off"
                        onChange={(e) => setPwd(e.target.value)}
                        required
                    />
                </label>

                <label>
                    <div>
                        Confirm Password:
                        <span
                            className={
                                validMatch && matchPwd ? 'valid' : 'hide'
                            }
                        >
                            <FontAwesomeIcon
                                icon={faCheck}
                                className="valid-icon"
                            />
                        </span>
                        <span
                            className={
                                validMatch || !matchPwd ? 'hide' : 'valid'
                            }
                        >
                            <FontAwesomeIcon
                                icon={faTimes}
                                className="invalid-icon"
                            />
                        </span>
                    </div>
                    <input
                        type="password"
                        name="confirm_pwd"
                        autoComplete="off"
                        onChange={(e) => setMatchPwd(e.target.value)}
                        required
                    />
                </label>
                <button
                    disabled={
                        !validName || !validUserName || !validPwd || !validMatch
                            ? true
                            : false
                    }
                    type="submit"
                >
                    Submit
                </button>
                {submitSuccess && (
                    <span className="register-message">{submitSuccess}</span>
                )}
            </form>
        </div>
    )
}

export default Register

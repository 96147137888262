import React from 'react'
import homePage1 from '../../home-page-1.png'
import homePage2 from '../../home-page-2.png'
import './Home.css'

function Home() {
  return (
      <div className='Home'>
          <img className='Home-brochure' src={homePage1} alt="Home Page 1" />
          <img className='Home-brochure' src={homePage2} alt="Home Page 2" />
      </div>
  )
}

export default Home
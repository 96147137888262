import './Login.css'
import { useState, useEffect, useRef } from 'react'
import axiosClient from '../../api/axiosConfig'
import { useNavigate, Link, useLocation } from 'react-router-dom'
import useAuth from '../../hooks/useAuth'

const Login = () => {
    const navigate = useNavigate()
    const userRef: any = useRef()
    const [user, setUser] = useState('')
    const [password, setPassword] = useState('')
    const [errMsg, setErrMsg] = useState('')
    const { setAuth }: any = useAuth()

    const location = useLocation()

    const from = location.state?.from?.pathname || '/'

    useEffect(() => {
        userRef.current.focus()
    }, [])

    const postLogin = async (e: any) => {
        e.preventDefault()

        const username = e.target.username.value
        const password = e.target.password.value

        try {
            const postBody = { username, password }
            const resp = await axiosClient.post('/auth/login', postBody)
            const data = resp.data
            console.log(`Example resp: `, resp)

            if (data?.loggedIn) {
                setErrMsg('')
                setAuth({ user: { ...data, username } })
                navigate(from, { replace: true })
            } else {
                setErrMsg(data.message)
            }
        } catch (error) {
            setErrMsg('Something went wrong ' + error)
        }
    }

    return (
        <div className="Login">
            <h2>Login</h2>
            <form className="form" onSubmit={postLogin}>
                <label>
                    <div>Username:</div>
                    <input
                        name="username"
                        type="text"
                        ref={userRef}
                        onChange={(e) => setUser(e.target.value)}
                        required
                        value={user}
                    />
                </label>
                <label>
                    <div>Password:</div>
                    <input
                        name="password"
                        type="password"
                        autoComplete="off"
                        onChange={(e) => setPassword(e.target.value)}
                        required
                        value={password}
                    />
                </label>

                <button type="submit">Submit</button>
                {errMsg ? (
                    <span className="login-error-message">
                        <p>{errMsg}</p>
                    </span>
                ) : null}
            </form>
        </div>
    )
}

export default Login

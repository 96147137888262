import { useLocation, Navigate, Outlet } from 'react-router-dom'
import useAuth from '../hooks/useAuth'
import CurrentPatient from './CurrentPatient/CurrentPatient'

const RequiredAuth = () => {
    const { auth }: any = useAuth()
    const location = useLocation()

    return auth ? (
    // return true ? ( // TODO for faster development
        <Outlet />
    ) : (
        <Navigate to="/login" state={{ from: location }} replace />
    )
}

export default RequiredAuth

import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

const initialRedirectTime = 10

function ThankYou() {
    const [redirectTime, setRedirectTime] = useState(initialRedirectTime)
    const navigate = useNavigate()
    useEffect(() => {
        setTimeout(() => {
            setRedirectTime(redirectTime - 1)
        }, 1000)
    }, [redirectTime])

    if (redirectTime === 0) {
        navigate('/')
    }
    return (
        <div className="ThankYou">
            <h1>
                Thank you for submitting your information. You will be
                redirected to the homepage in {redirectTime}
            </h1>
        </div>
    )
}

export default ThankYou

import axios from "axios";

export default axios.create({
    baseURL:
        process.env.NODE_ENV === 'development'
            ? 'http://localhost:3100' // local node
            : 'https://0odubcov3f.execute-api.us-west-1.amazonaws.com/default/', // api gateway url
    headers: {
        'Content-Type': 'application/json',
    },
    // withCredentials: true,
})

import React, { useEffect, useState } from 'react'
import axiosClient from '../../api/axiosConfig'
import './Inquire.css'
import { timeframeConstant } from '../../constants'
import { Button } from 'react-bootstrap'
import { toast } from 'react-toastify'

import { baseToastOptions } from '../../baseToastOptions'
import { preferredCitiesOptions } from '../../constants'
import { useNavigate } from 'react-router-dom'

function Inquire() {
    const [contactPerson, setContactPerson] = useState('')
    const [contactAddress, setContactAddress] = useState('')
    const [contactRelationship, setContactRelationship] = useState('')
    const [isPOA, setIsPOA] = useState<null | boolean>(null)
    const [isConservator, setIsConservator] = useState<null | boolean>(null)
    const [patientName, setPatientName] = useState('')
    const [patientAddress, setPatientAddress] = useState('')
    const [DOB, setDOB] = useState('')
    const [isLowIncome, setIsLowIncome] = useState<null | boolean>(null)
    const [telephone, setTelephone] = useState('')
    const [roomAndBoard, setRoomAndBoard] = useState<null | boolean>(null)
    const [boardAndCare, setBoardAndCare] = useState<null | boolean>(null)
    const [assistedLiving, setAssistedLiving] = useState<null | boolean>(null)
    const [memoryCare, setMemoryCare] = useState<null | boolean>(null)
    const [noIncome, setNoIncome] = useState<null | boolean>(null)
    const [preferredCities, setPreferredCities] = useState<any>([])
    const [timeframe, setTimeframe] = useState('')

    const navigate = useNavigate()

    async function handleSubmit(e: any) {
        e.preventDefault()
        const id = toast.loading('Submitting patient information...')

        const patientInformation = {
            // contactAddress,
            contactRelationship,
            isPOA,
            isConservator,
            patientName,
            // patientAddress,
            DOB,
            // isLowIncome,
            contactPerson,
            telephone,
            boardAndCare,
            assistedLiving,
            memoryCare,
            // noIncome,
            preferredCities,
            timeframe,
        }
        console.log(`Example patientInformation: `, patientInformation)

        try {
            const response: any = await axiosClient.post(
                '/inquire',
                patientInformation
            )
            // const response = { ok: true } // TODO for faster development
            console.log(`Example response: `, response)
            console.log(`Example response.data: `, response.data)
            if (response.data?.ok) {
                toast.update(id, {
                    render: 'Successfully submitted patient information',
                    type: 'success',
                    ...baseToastOptions,
                })
                navigate('/thank-you')
            } else {
            }
        } catch (error) {
            console.log(`Example error: `, error)
            toast.update(id, {
                render: 'Something went wrong. Please try again.',
                type: 'error',
                ...baseToastOptions,
            })
            return
        }
    }

    function handlePreferredCities(option: string) {
        if (preferredCities.includes(option)) {
            setPreferredCities(
                preferredCities.filter((city: any) => city !== option)
            )
        } else {
            const newPreferredCities = [...preferredCities]
            newPreferredCities.push(option)
            setPreferredCities(newPreferredCities)
        }
    }
    return (
        <div className="Inquire">
            <h2>Inquire</h2>

            <form className="form">
                <section className="Inquire-parent-section">
                    <section className="Inquire-section">
                        <h2>Contact Person</h2>
                        <label>
                            <div>Name:</div>
                            <input
                                onChange={(e) =>
                                    setContactPerson(e.target.value)
                                }
                                value={contactPerson}
                            />
                        </label>
                        <label>
                            <div>Phone number:</div>
                            <input
                                onChange={(e) => setTelephone(e.target.value)}
                                value={telephone}
                            />
                        </label>

                        <label>
                            <div>Relationship:</div>
                            <input
                                onChange={(e) =>
                                    setContactRelationship(e.target.value)
                                }
                                value={contactRelationship}
                            />
                            <label className="checkbox">
                                <input
                                    type="checkbox"
                                    onChange={(e) => setIsPOA(!isPOA)}
                                    checked={Boolean(isPOA)}
                                />
                                POA
                            </label>
                            <label className="checkbox">
                                <input
                                    type="checkbox"
                                    onChange={(e) =>
                                        setIsConservator(!isConservator)
                                    }
                                    checked={Boolean(isConservator)}
                                />
                                Conservator
                            </label>
                        </label>
                    </section>
                    <section className="Inquire-section">
                        <h2>Patient Information</h2>
                        <label>
                            <div>Name:</div>
                            <input
                                onChange={(e) => setPatientName(e.target.value)}
                                value={patientName}
                            />
                        </label>
                        {/* <label>
                            <div>Address:</div>
                            <input
                                onChange={(e) =>
                                    setPatientAddress(e.target.value)
                                }
                                value={patientAddress}
                            />
                        </label> */}
                        <label>
                            <div>DOB:</div>
                            <input
                                type="date"
                                onChange={(e) => setDOB(e.target.value)}
                                value={DOB}
                            />
                        </label>
                    </section>
                </section>
                <section className="Inquire-parent-section">
                    <section className="Inquire-section">
                        <h2>Looking For:</h2>
                        <label className="checkbox">
                            <input
                                type="checkbox"
                                onChange={(e) => setRoomAndBoard(!roomAndBoard)}
                                checked={Boolean(roomAndBoard)}
                            />
                            Room & Board
                        </label>
                        <label className="checkbox">
                            <input
                                type="checkbox"
                                onChange={(e) => setBoardAndCare(!boardAndCare)}
                                checked={Boolean(boardAndCare)}
                            />
                            Board & Care
                        </label>
                        <label className="checkbox">
                            <input
                                type="checkbox"
                                onChange={(e) =>
                                    setAssistedLiving(!assistedLiving)
                                }
                                checked={Boolean(assistedLiving)}
                            />
                            Assisted Living
                        </label>
                        <label className="checkbox">
                            <input
                                type="checkbox"
                                onChange={(e) => setMemoryCare(!memoryCare)}
                                checked={Boolean(memoryCare)}
                            />
                            Memory Care
                        </label>
                    </section>
                    <section className="Inquire-section">
                        <h2>Timeframe:</h2>
                        <label className="checkbox">
                            <input
                                type="radio"
                                value={timeframeConstant.ASAP}
                                onChange={(e) => setTimeframe(e.target.value)}
                                checked={timeframe === timeframeConstant.ASAP}
                            />
                            ASAP
                        </label>
                        <label className="checkbox">
                            <input
                                type="radio"
                                value={timeframeConstant.ONE_TO_THREE_MONTHS}
                                onChange={(e) => setTimeframe(e.target.value)}
                                checked={
                                    timeframe ===
                                    timeframeConstant.ONE_TO_THREE_MONTHS
                                }
                            />
                            1-3 months
                        </label>
                        <label className="checkbox">
                            <input
                                type="radio"
                                value={timeframeConstant.THREE_TO_SIX_MONTHS}
                                onChange={(e) => setTimeframe(e.target.value)}
                                checked={
                                    timeframe ===
                                    timeframeConstant.THREE_TO_SIX_MONTHS
                                }
                            />
                            3-6 months
                        </label>
                    </section>
                </section>

                <div className="Inquire-parent-section">
                    <div className="Inquire-section full-width">
                        {/* This could be a search box */}
                        <label>
                            <h2>Preferred Cities:</h2>
                            <div style={{ columnCount: 3 }}>
                                {preferredCitiesOptions.map(
                                    (option: string) => {
                                        return (
                                            <label key={option} className="checkbox">
                                                <input
                                                    type="checkbox"
                                                    onChange={() =>
                                                        handlePreferredCities(
                                                            option
                                                        )
                                                    }
                                                    checked={preferredCities.includes(
                                                        option
                                                    )}
                                                />
                                                <span
                                                    style={{
                                                        textTransform:
                                                            'capitalize',
                                                    }}
                                                >
                                                    {option}
                                                </span>
                                            </label>
                                        )
                                    }
                                )}
                            </div>
                        </label>
                        <div></div>
                    </div>
                </div>
                <div className="Inquire-parent-section-submit">
                    <Button className="Inquire-submit" onClick={handleSubmit}>
                        Submit
                    </Button>
                </div>
            </form>
        </div>
    )
}

export default Inquire

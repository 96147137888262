import { Button } from 'react-bootstrap'
import { useCurrentPatient } from '../../state'
import './CurrentPatient.css'
import { toast } from 'react-toastify'
import { baseToastOptions } from '../../baseToastOptions'
import axiosClient from '../../api/axiosConfig'

function CurrentPatient() {
    const [currentPatient, setCurrentPatient] = useCurrentPatient() as any
    // example currentPatient = {
    //     "isConfused": true,
    //     "preferredCities": [
    //         "bbb",
    //         "cc"
    //     ],
    //     "status": "MATCHING",
    //     "noIncome": null,
    //     "email": "andrewcbuensalida@gmail.com",
    //     "contactPerson": "a",
    //     "socialSecurityIncome": "3",
    //     "firstName": "b",
    //     "ambulatoryStatus": "independent",
    //     "isBathing": true,
    //     "isLowIncome": false,
    //     "isFeeding": null,
    //     "boardAndCare": null,
    //     "sk": "patient",
    //     "telephone": "1",
    //     "roomAndBoard": false,
    //     "pk": "41731c5c-2713-48a9-8868-4412170d7260",
    //     "zipCode": "",
    //     "id": "41731c5c-2713-48a9-8868-4412170d7260"
    // }

// TODO not finished in the backend
  async function handleEmailMatches() {
        console.log('in handleEmailMatches')
        const id = toast.loading('Emailing matches to patient. Please wait...')
        if (!currentPatient) {
            toast.update(id, {
                render: 'Please select a patient first.',
                type: 'error',
                ...baseToastOptions,
            })
            return
        }
        if (!currentPatient.email) {
            toast.update(id, {
                render: 'Patient must have an email.',
                type: 'error',
                ...baseToastOptions,
            })
            return
        }
        try {
            const response: any = await axiosClient.post(
                '/email-facilities-to-patient',
                { currentPatient }
            )
            console.log(`Example response.data: `, response.data)
            setCurrentPatient(response.data.newCurrentPatient)
            toast.update(id, {
                render: 'Successfully emailed matching facilities to patient. ',
                type: 'success',
                ...baseToastOptions,
            })
        } catch (error: any) {
            console.log(error)
            toast.update(id, {
                render:
                    'Failed to email matching facilities to patient. ' +
                    (error.response?.data?.message ||
                        error.response?.statusText ||
                        error.message), // error.response.data.message is for when nodemailer errors like wrong username. error.response.statusText is for when url is wrong. error.message is for if the server is down. TODO might have to do this with handleMatchToPatient in Facilities.tsx
                type: 'error',
                ...baseToastOptions,
            })
        }
    }
    return (
        <section className="CurrentPatient">
            <h2>Current Patient</h2>
            <div className="CurrentPatient-body">
                {currentPatient ? (
                    <>
                        <div>
                            <span>
                                <b>Name:</b> {currentPatient.firstName}
                            </span>
                            <br />
                            <span>
                                <b>Telephone:</b> {currentPatient.telephone}
                            </span>
                            <br />
                            <span>
                                <b>Status:</b> {currentPatient.status}
                            </span>
                        </div>
                        <div>
                            <span>
                                <b>Low income?</b> {currentPatient.isLowIncome}
                            </span>
                            <br />
                            <span>
                                <b>Email:</b> {currentPatient.email}
                            </span>
                            <br />
                            <span>
                  <b>Matched facilities: </b>
                  {/* TODO not finished in the backend AS-12 */}
                                {/* <Button
                                    size="sm"
                                    onClick={handleEmailMatches}
                                    disabled={
                                        !currentPatient.matchedFacilities ||
                                        currentPatient.matchedFacilities
                                            ?.length === 0
                                    }
                                >
                                    Email matches
                                </Button> */}
                                {/* TODO duplicate code in Patients.tsx */}
                                <ul>
                                    {currentPatient.matchedFacilities?.map(
                                        (facility: any) => {
                                            return (
                                                <li>
                                                    <span className="matched-facilities-span">
                                                        {
                                                            facility[
                                                                'Facility Name'
                                                            ]
                                                        }
                                                    </span>
                                                </li>
                                            )
                                        }
                                    )}
                                </ul>
                            </span>
                        </div>
                        <div>
                            <span>
                                <b>Preffered cities:</b>{' '}
                                {currentPatient.preferredCities?.join(', ')}
                            </span>
                            <br />
                            <span>
                                <b>Room and board?</b>{' '}
                                {currentPatient.roomAndBoard}
                            </span>
                            <br />
                            <span>
                                <b>Board and care?</b>{' '}
                                {currentPatient.boardAndCare}
                            </span>
                        </div>
                    </>
                ) : (
                    <div>Select a patient</div>
                )}
            </div>
        </section>
    )
}

export default CurrentPatient

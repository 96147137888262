import React, { Context } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGraduationCap } from '@fortawesome/free-solid-svg-icons'
import { useNavigate, NavLink, Link } from 'react-router-dom'
import useAuth from '../../hooks/useAuth'
import './Header.css'

const Header = () => {
    const { auth, setAuth }: any = useAuth()
    console.log(`Example auth: `, auth)

    const navigate = useNavigate()

    function logOut() {
        setAuth(null)
        navigate('/')
    }

    return (
        <header className="Header">
            <div className="Header-logo">
                <Link to="/">
                  {/* TODO All Senior Care Options Networks and Afilliates */}
                    <h1>ASCONA</h1>
                </Link>
            </div>
            <nav className="Header-nav">
                {auth?.user ? (
                    <>
                        <NavLink className="nav-link" to="/patients">
                            Patients
                        </NavLink>
                        <NavLink className="nav-link" to="/facilities">
                            Facilities
                        </NavLink>
                        <NavLink className="nav-link" to="/users">
                            Users
                        </NavLink>
                    </>
                ) : (
                    <NavLink className="nav-link" to="/inquire">
                        Inquire
                    </NavLink>
                )}
                {/* {auth?.user ? (
                    <button className="button" onClick={logOut}>
                        Logout
                    </button>
                ) : (
                    <>
                        <button
                            className="button"
                            onClick={() => navigate('/Login')}
                        >
                            Login
                        </button>
                        <button
                            className="button"
                            onClick={() => navigate('/Register')}
                        >
                            Register
                        </button>
                    </>
                )} */}
            </nav>
        </header>
    )
}

export default Header

import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import '../table-style.css'
import axiosClient from '../../api/axiosConfig'
import './Facilities.css'
import { useCurrentPatient } from '../../state'
import { toast } from 'react-toastify'
import { baseToastOptions } from '../../baseToastOptions'
import { facility } from '../../types'

function Facilities() {
    const [facilities, setFacilities] = useState([])
    const [error, setError] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [currentPatient, setCurrentPatient] = useCurrentPatient() as any

    useEffect(() => {
        async function getFacilities() {
            setIsLoading(true)
            try {
                const response: any = await axiosClient.get(
                    '/facilities/ALAMEDA'
                )
                console.log(response)
                setFacilities(response.data.facilities)
                console.log(
                    `Example response.data.facilities: `,
                    response.data.facilities
                )
            } catch (error: any) {
                console.log(error)
                setError(error.message)
            } finally {
                setIsLoading(false)
            }
        }
        getFacilities()
    }, [])

    async function handleMatchToPatient(facility: facility) {
        console.log(`Example facility: `, facility)

        const id = toast.loading(
            'Adding facility to patient matches. Please wait...'
        )
        if (!currentPatient) {
            toast.update(id, {
                render: 'Please select a patient first.',
                type: 'success',
                ...baseToastOptions,
            })
            return
        }
        try {
            const newMatchedFacilities = currentPatient.matchedFacilities
                ? [...currentPatient.matchedFacilities, facility]
                : [facility]
            const response: any = await axiosClient.post('/patient', {
                attributeName: 'matchedFacilities',
                attributeValue: newMatchedFacilities,
                currentPatientPk: currentPatient.pk,
            })
            console.log(`Example response.data: `, response.data)
            // if(response.data.currentPatient)
            setCurrentPatient(response.data.newCurrentPatient)
            toast.update(id, {
                render: 'Successfully added facility to patient. ',
                type: 'success',
                ...baseToastOptions,
            })
        } catch (error: any) {
            console.log(error)
            toast.update(id, {
                render:
                    'Failed to add facility to patient matches. ' +
                    error.message,
                type: 'error',
                ...baseToastOptions,
            })
        }
    }
    return (
        <div className="Facilities">
            <h2 className="Facilities-title">Facilities</h2>
            {isLoading ? (
                <h2>Loading...</h2>
            ) : error ? (
                <div>{error}</div>
            ) : (
                <table id="table-style">
                    <thead>
                        <tr>
                            <th>Facility Name</th>
                            <th>Address</th>
                            <th>Telephone</th>
                            <th>Email</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {facilities.map((facility: any) => {
                            return (
                                <tr key={facility['Facility Number']}>
                                    <td>{facility['Facility Name']}</td>
                                    <td>{facility['Facility Address']}</td>
                                    <td>
                                        {facility['Facility Telephone Number']}
                                    </td>
                                    <td>{facility['Facility Email']}</td>
                                    <td>
                                        {currentPatient && (
                                            <Button
                                                onClick={() =>
                                                    handleMatchToPatient(
                                                        facility
                                                    )
                                                }
                                            >
                                                Match to patient
                                            </Button>
                                        )}
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            )}
        </div>
    )
}

export default Facilities
